import React from 'react';
import Layout from '../components/layout';
import styles from './privacy-notice.module.scss';

export default ({ data }) => {
  return (
    <Layout>
      <div className={'columns'}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contentbox}`}>
            <h1 className={`title`}>Employee and Candidate Privacy Notice</h1>
            <p className={`content is-size-7`}>
              The purpose of this Privacy Notice (“<strong>Notice</strong>”) is
              to inform corporate and clinical employees (“
              <strong>employees</strong>”) of Trustaff Management, LLC; Fastaff,
              LLC; U.S. Nursing Corporation; HealthCare Support Staffing LLC;
              CardioSolution LLC; and their subsidiaries and affiliates (the “
              <strong>Company</strong>” or “<strong>we</strong>” or “
              <strong>us</strong>”), of the categories of Personal Information
              we collect and the purposes for which we use such personal
              information. This Notice is also to inform individuals who apply
              to be clinical - whether travel nurse or other - or corporate
              employees (collectively referred to as “candidates”) of the
              categories of Personal Information we collect and the purposes for
              which we use such Personal Information. “
              <strong>Personal Information</strong>” means information that
              identifies, relates to, describes, is reasonably capable of being
              associated with, or could be reasonably linked with a particular
              individual, and includes the categories and types of information
              listed in the chart below.
            </p>
            <p className={`content is-size-7`}>
              This Notice is intended to comply with all applicable laws,
              including but not limited to the California Consumer Privacy Act.
              If any provision below conflicts with a legal requirement, then
              the Company will comply with the applicable law.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              1. Collection and Use of Personal Information for Business
              Purposes
            </h2>

            <p className={`content is-size-7`}>
              The Company may collect, use, and disclose employee and candidate
              Personal Information for business purposes only and consistent
              with applicable laws. Personal Information collected by any one of
              us may be shared with and used by any other Company entity
              consistent with the business purposes described below. Where the
              Company discloses Personal Information to third parties, it does
              so for the same business purposes described below and where
              appropriate, requires that such parties maintain its
              confidentiality and maintain appropriate systems and processes to
              ensure its security and protection.
            </p>
            <p className={`content is-size-7`}>
              The chart below shows the categories of Personal Information we
              may collect about you and use when you apply to be a corporate or
              clinical employee and, if you are an employee, to manage your
              employment.
            </p>
            <p className={`content is-size-7`}>
              Please note that we do not collect from candidates all of the
              personal information identified below. We collect certain personal
              information only from employees. The chart identifies the
              categories and types of personal information that are not
              collected from candidates but that may be collected once an
              individual becomes an employee of the Company.
            </p>
            <table
              cols="2"
              className={`table is-bordered is-striped is-full-width`}
            >
              <tbody>
                <tr>
                  <th width="50%">
                    <p className={`content`}>
                      Category of Personal Information
                    </p>
                  </th>
                  <th width="50%">
                    <p className={`content`}>How we use it</p>
                  </th>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>Contact and identification information:</strong>{' '}
                      such as your name, home and work address, personal and
                      work telephone numbers, personal and work email addresses,
                      photo, physical description, and signature.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this information to open and maintain your
                      candidate profile and employee records, confirm your
                      identity, communicate with you for internal business
                      purposes or emergencies, maintain an internal employee and
                      candidate directory, and grant you access to internal
                      systems.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>
                        Job-related information and qualifications:
                      </strong>{' '}
                      such as position applied for, previous job roles,
                      education, awards, qualifications, certificates, licenses,
                      financial relationships, compensation and salary
                      information, eligibility for and participation in benefit
                      schemes and award programs, and any other information you
                      choose to provide in your resumes and/or applications as
                      well as work anniversary information and skills and
                      experience over the employment period.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this information to assess your personal and
                      professional development, your suitability for job
                      placements, promotions, benefits and other awards, job
                      moves and staff restructuring, conflict of interest
                      reporting, and to fulfill our obligations to regulators
                      (including demonstrating the suitability of employees for
                      their role or the suitability of candidates for particular
                      positions).
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>National identification information</strong>: such
                      as your country of birth or the country where you are a
                      registered national or citizen, and any visa or other
                      right to work.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this information to determine your eligibility
                      to work and fulfill our obligations to relevant government
                      authorities.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>Age and benefits information</strong>: including
                      your date of birth, social security number, marital
                      status, information about your pension and other welfare
                      benefits, insurance policy information.
                      <br />
                      <br />
                      *The Company only requires social security number, marital
                      status, welfare benefits, and insurance policy information
                      if an individual becomes an employee of the Company.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this to maintain employment and candidate
                      records and assess your eligibility for and/or pay you any
                      statutory or other benefits to which you may be entitled.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>Health and disability records</strong>: such as
                      information relating to any disability or health condition
                      that is relevant to your role, including the results of
                      any pre- or post-employment physical or screening
                      examination, physical characteristics, temperature checks
                      and screening, disease symptoms, disease testing and
                      results.
                      <br />
                      <br />
                      *We only collect and use health and disability records
                      employees of the Company, not for candidates.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this information to address our legal
                      obligations, make any adjustments required to assist you
                      in the performance of your role, determine whether you are
                      fit to undertake tasks required by your job role, and to
                      monitor and address workplace and individuals’ safety,
                      including disease prevention and control.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>
                        Beneficiaries, dependents and emergency contact
                        information:
                      </strong>{' '}
                      information about any beneficiaries, dependents, emergency
                      contacts and next of kin.
                      <br />
                      <br />
                      *We only collect and use these records for employees of
                      the Company, not candidates.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this information to maintain your employment
                      records and contact the designated contacts in the case of
                      an emergency.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>Financial Information:</strong> such as your bank
                      account details.
                      <br />
                      <br />
                      *We only collect and use financial information for
                      employees of the Company, not candidates.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this information to pay you or provide other
                      benefits and process any expense claims.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>Travel-related records:</strong> frequent flyer
                      numbers, itineraries, flight, train, rental car, car
                      service, hotel or other similar information.
                      <br />
                      <br />* We only collect and use travel-related records for
                      employees of the Company, not candidates.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this information to arrange or reimburse
                      travel, contact you during travel, as necessary with
                      travel service providers, or in an emergency situation.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>Professional memberships:</strong> information
                      about your membership in professional bodies and
                      consultation bodies, including trade unions.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this to assess your personal and professional
                      development and perform our legal obligations in relation
                      to your professional or trade membership.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>
                        Results of background checks and screening:
                      </strong>{' '}
                      such as education verification, criminal records and
                      driver’s license checks.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this to determine your suitability for certain
                      job roles (to the extent permitted or required by law) and
                      determine your eligibility for certain benefits (such as a
                      company car).
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>References:</strong> such as opinions and comments
                      of any previous employers.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this to determine your suitability for certain
                      job roles and to fulfill our obligations to regulators
                      (including demonstrating the suitability of employees for
                      their role to regulators and professional bodies).
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>Salary and benefits information:</strong> salary
                      details, bonus payments, pension details, medical
                      insurance, share scheme details, company car scheme
                      details.
                      <br />
                      <br />
                      *We only collect and use the salary and benefits
                      information listed here for employees of the Company, not
                      candidates.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this information to allocate resources and
                      process payments of benefits, salary and any other amounts
                      we owe you.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>Performance and development records:</strong>{' '}
                      training records, records of courses and training
                      undertaken, performance reviews and assessments.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this information for career planning and
                      skills monitoring, assessing your suitability for
                      promotions, job moves, job placements, and staff
                      restructuring, providing references for future
                      applications and fulfill our obligations to regulators
                      (including demonstrating the suitability of employees for
                      their role to regulators and professional bodies).
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>
                        Disciplinary, capability and conduct records:
                      </strong>{' '}
                      details of warnings and other records relating to conduct.
                      <br />
                      <br />* We only collect and use disciplinary, capability
                      and conduct records for employees of the Company, not
                      candidates.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this information for assessing and taking
                      action in relation to disciplinary, capability, grievance
                      and conduct issues, maintaining your employment records,
                      monitoring and improving our human resources procedures
                      and processing and fulfilling our obligations to
                      regulators.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>Absence records:</strong> records relating to time
                      away from the office, including vacation, illness and
                      paternity/maternity leave.
                      <br />
                      <br />* We only collect and use absence records for
                      employees of the Company, not candidates.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this information to monitor absences and
                      sickness, make decisions relating to human resource
                      allocation, salary and benefit changes and address other
                      issues that may arise from absences.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>Health and safety records:</strong> information
                      relating to health and safety in the workplace, accidents
                      and near misses.
                      <br />
                      <br />* We only collect and use health and safety records
                      for employees of the Company, not candidates.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this to address our legal obligations to you
                      in relation to health and safety in the workplace.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>Information we obtain from monitoring:</strong>{' '}
                      such as records of your use of our computer systems or the
                      Internet, and recordings from surveillance cameras on our
                      business premises.
                      <br />
                      <br />* We only collect and use the monitoring information
                      identified above for employees of the Company, not
                      candidates.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this to monitor your compliance with our
                      internal policies and procedures, to investigate security
                      breaches and misuse of computer equipment and systems, to
                      protect the safety of employees and third parties, and to
                      protect our property from theft, vandalism and damage.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>
                        Equal opportunities monitoring information:
                      </strong>{' '}
                      such as your ethnicity, religion, gender and sexual
                      orientation.
                      <br />
                      <br />* We only collect and use equal opportunities
                      monitoring information for employees of the Company, not
                      candidates.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this to conduct equal opportunity and
                      diversity monitoring where permitted or required by law.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>
                        Information regarding your phone or mobile device:
                      </strong>{' '}
                      such as your mobile phone number and mobile phone or
                      device billing and usage records.
                      <br />
                      <br />* We only collect and use phone and mobile device
                      information for employees of the Company, not candidates.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this, in accordance with applicable policies,
                      to reimburse you for use of your mobile phone or device
                      for employment-related purposes.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className={`content is-size-7`}>
                      <strong>Biometric information:</strong> such as facial
                      recognition, fingerprint or handpunch/hand-geometry data
                      collected by a biometric time clock.
                      <br />
                      <br />* We only collect and use biometric information for
                      employees of the Company, not candidates.
                    </p>
                  </td>
                  <td>
                    <p className={`content is-size-7`}>
                      We will use this to monitor and record your hours of work.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <h2 className={`title is-5 has-text-info`}>
              2. Additional Purposes for Using Personal Information
            </h2>

            <p className={`content is-size-7`}>
              In addition to the uses set forth above, the Company may use and
              share the categories of Personal Information identified:
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                To comply with applicable legal and regulatory requests and
                obligations (including investigations).
              </li>
              <li>To establish or defend legal claims and allegations.</li>
              <li>
                For security or the prevention, detection, or investigation of
                fraud, suspected or actual illegal activity, violations of
                company policy or rules, or other misconduct.
              </li>
              <li>
                To seek advice from lawyers, auditors and other professional
                advisers.
              </li>
            </ul>

            <h2 className={`title is-5 has-text-info`}>3. Questions</h2>

            <p className={`content is-size-7`}>
              If you have any questions about this Notice, or to request this
              Notice in another format, please contact{' '}
              <a href="mailto:legal@ingenovishealth.com">
                Legal@IngenovisHealth.com
              </a>
              .
            </p>

            <p className={`content is-size-7`}>
              Updated effective date: 4-18-2022
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
